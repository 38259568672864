import * as React from 'react';
import { useState } from 'react';
import { extendTheme, styled } from '@mui/material/styles';
import WorkIcon from '@mui/icons-material/Work';
import DescriptionIcon from '@mui/icons-material/Description';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import InfoIcon from '@mui/icons-material/Info';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { PageContainer } from '@toolpad/core/PageContainer';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ResumeViewer from './ResumeViewer';
import { TypeAnimation } from 'react-type-animation';

import CodeIcon from '@mui/icons-material/Code';
import { useTheme, useMediaQuery } from '@mui/material';
import Diversity3Icon from '@mui/icons-material/Diversity3';

import { TextField, Button, IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import SendIcon from "@mui/icons-material/Send";
import './App.css'; // Import the CSS file
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


const getNavigation = () => [
  { kind: 'header', title: 'Portfolio' },
  { segment: 'about', title: 'About Me', icon: <InfoIcon /> },
  {
    segment: 'experience',
    title: 'Experience',
    icon: <WorkIcon />,
  },
  {
    segment: 'internships',
    title: 'Internships',
    icon: <Diversity3Icon />,
  },
  {
    segment: 'projects',
    title: 'Projects',
    icon: <CodeIcon />,
  },
  { segment: 'contact', title: 'Contact', icon: <ContactMailIcon /> },
  { segment: 'resume', title: 'Resume', icon: <DescriptionIcon /> },
];




const getTheme = () => extendTheme({
  colorSchemes: { light: true, dark: true },
  colorSchemeSelector: 'class',
  breakpoints: {
    values: { xs: 0, sm: 600, md: 600, lg: 1200, xl: 1536 }
  }
});

const useDemoRouter = (initialPath) => {
  const [pathname, setPathname] = React.useState(initialPath);
  return {
    pathname,
    searchParams: new URLSearchParams(),
    navigate: (path) => setPathname(String(path))
  };
};

const Section = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2)
}));

const AboutSection = ({ resume }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const imageSize = isMobile ? 100 : isTablet ? 140 : 180;

  return (
    <Grid item xs={12}>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: theme.palette.background.paper,
          borderRadius: 3,
        }}
      >
        <Box
          component="img"
          src="/image.png" // Replace with your actual image path
          alt="Gaurav Avula"
          className="slide-in-elliptic-top-fwd" // Apply the class here
          sx={{
            width: imageSize,
            height: imageSize,
            borderRadius: '50%',
            objectFit: 'cover',
            mb: 2,
            boxShadow: 3,
          }}
        />
        <Typography className="tracking-in-expand " variant="h4" fontWeight="bold" color="primary">
          {resume.name}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          <TypeAnimation
            sequence={[
              'AI & Cloud Enthusiast',
              1500,
              'Full Stack Developer',
              1500,
              'Software Engineer',
              1500,
              'Tech Enthusiast',
              1500,
              'Aviation Geek ✈️',
              1500,
              'Apple Tech Lover',
              1500,
              'Always Trying New Things',
              1500,
              'Loves Long Drives',
              1500,
              'Reads Tech for Fun',
              1500,
            ]}
            speed={50}
            wrapper="span"
            repeat={Infinity}
          />
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {resume.summary}
        </Typography>
      </Paper>
    </Grid>
  );
};

const ExperienceSection = ({ experiences }) => (
  <Grid size={12}>
    <Section>
      {experiences.length === 0 ? (
        <Typography>Loading experience...</Typography>
      ) : (
        experiences.map((exp, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRadius: 3,
              mb: 3
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              {/* <Avatar src={exp.url} alt={exp.company} sx={{ width: 50, height: 50, mr: 2 }} /> */}
              <img src={exp.url} alt={exp.company} style={{ width: 150, height: 30, marginRight: 16 }} />
              <Typography variant="h6">{exp.role}</Typography>
            </Box>
            <Typography variant="subtitle1">
              <strong>({exp.duration})</strong>
            </Typography>
            <ul>
              {exp.description.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
          </Paper>
        ))
      )}
    </Section>
  </Grid>
);



const ProjectsSection = ({ projects }) => (
  <Grid size={12}>
    <Section>

      {projects.length === 0 ? (
        <Typography>Loading projects...</Typography>
      ) : (
        projects.map((project, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={(theme) => ({
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.background.paper, // Use theme color
              borderRadius: 3,
              mb: 3
            })}
          >
            <Typography variant="h6">{project.title}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1 }}>
              Tools Used: {project.tools.join(', ')}
            </Typography>
            <ul>
              {project.description.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>

          </Paper>
        ))
      )}
    </Section>
  </Grid>
);

const ContactSection = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Fetch IP address
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const ipAddress = ipData.ip;

      // Get device info
      const deviceInfo = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
      };

      // Combine form data and additional info
      const payload = {
        event: 'Contact Form Submission',
        formData,
        ipAddress,
        deviceInfo,
        timestamp: new Date().toISOString(),
      };

      // Send the data to ntfy.sh
      await fetch('https://ntfy.sh/98WDOFKiMpTmfGO7', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={3}
        sx={(theme) => ({
          padding: 4,
          textAlign: "center",
          borderRadius: 3,
          backgroundColor: theme.palette.background.paper,
        })}
      >


        <Typography variant="h4" color="primary" fontWeight="bold" gutterBottom>
          Contact Me
        </Typography>

        {/* 📩 Email Link */}
        <Typography variant="body1">
          <EmailIcon sx={{ verticalAlign: "middle", mr: 1 }} />
          <a href="mailto:sudo@gauravavula.com" style={{ textDecoration: "none", color: "#1976D2" }}>
            sudo@gauravavula.com
          </a>
        </Typography>

        {/* 🌐 Social Media Links */}
        <Box mt={2}>
          <IconButton href="https://linkedin.com/in/gauravavula" target="_blank" color="primary">
            <LinkedInIcon fontSize="large" />
          </IconButton>
          <IconButton href="https://github.com/gauravav" target="_blank" color="default">
            <GitHubIcon fontSize="large" />
          </IconButton>
          <IconButton href="https://twitter.com/avulagaurav" target="_blank" color="primary">
            <TwitterIcon fontSize="large" />
          </IconButton>
        </Box>

        {/* 📝 Contact Form */}
        {!submitted ? (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Your Name"
              name="name"
              variant="outlined"
              margin="normal"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Your Email"
              name="email"
              type="email"
              variant="outlined"
              margin="normal"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              label="Your Message"
              name="message"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              required
            />
            {/* // css class for div .wobble-hor-bottom */}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              className="wobble-hor-bottom" // Add class here
              sx={{ mt: 2 }}
            >
              Send Message
            </Button>

          </Box>
        ) : (
          <Typography variant="h6" color="green" sx={{ mt: 3 }}>
            ✅ Thank you! Your message has been sent.
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};




// const ModalStyle = (theme) => ({
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 300,
//   backgroundColor: theme.palette.background.paper,
//   borderRadius: 3,
//   boxShadow: 24,
//   p: 4,
//   textAlign: 'center',
// });

const dialogues = [
  "Yo wizard, coding any magic today? 🧙‍♂️",
  "Hey star, the galaxy missed you 🌌",
  "Brace yourself, legend just entered 🚀",
  "Reporting for duty, commander! 👨‍✈️",
  "What’s cookin’, chef? 🍳",
  "Knock knock… it’s awesomeness at the door 🚪",
  "The vibes just improved. Thanks, you 😎",
  "Back in action, agent 007? 🕶️",
  "Look alive, rockstar just dropped 🎤",
  "Hustle mode ON, right grindmaster? 🔥",
  "You + this site = pure 🔥, right legend?",
  "Long time no see, trailblazer 🌟",
  "Yo maverick, let’s shake things up 🌀",
  "The MVP is here – welcome, champ 🏀",
  "Hey tech overlord, take the wheel 🕹️",
  "Just in: genius has entered the chat 🧠",
  "Rise and grind, hero 💪",
  "Buckle up, pilot! Let’s fly high ✈️",
  "What's the mission, secret agent? 🕵️‍♂️",
  "Bonjour creative soul! 🎨",
  "Welcome back, unicorn 🦄",
  "Alert: legendary being online 📡",
  "Ooh la la, is that fancy human? 💃",
  "Vibes detected: vibe curator has arrived 🎧",
  "Is it a bird? A plane? Nope, just superhuman 🦸‍♂️",
  "Holy code lines, hacker pro! 💻",
  "Welcome to the matrix, Neo 🕶️",
  "Bless up, hustler 💼",
  "Big brain energy loading… Welcome, genius 🧠",
  "A wild creative beast appears! 🐉",
  "Welcome aboard, captain! 🛫",
  "Yo friend, ready to rock? 🤘",
  "Hola amigo! 🌮",
  "Wassup champ? 🏆",
  "Hey there, legend! 🌟",
  "Look who just dropped in – you! 👀",
  "Ahoy matey! ⚓️",
  "Namaste traveler 🙏",
  "Yo techie! 💻",
  "It’s you, the cool one! 😎",
  "Greetings, hero! 🦸‍♂️",
  "Hey hey, boss! 💼",
  "Hey rockstar! 🎸",
  "Bonjour, world explorer! 🗺️",
  "What’s crackin’, buddy? 🧩",
  "Hey navigator! Ready to explore? 🧭",
  "Hola dreamer! ☁️",
  "Hey speedster! 🏎️",
  "Knock knock, who’s there? It’s you! 🚪",
  "Salutations, commander! 🛰️",
  "Hey trailblazer! 🔥",
  "Sup hacker! 💾",
  "Nice to meet ya, mystery human 🤖",
  "Hello, world-changer! 🌍",
  "Hey boss coder! 👨‍💻",
  "Lookin’ sharp, chief! ✨",
  "Cheers, champion! 🍻",
  "Welcome back, superstar! 💫"
];


export default function DashboardLayoutBasic(props) {
  const { window } = props;
  const router = useDemoRouter('/about');
  const demoWindow = window ? window() : undefined;
  const [resume, setResume] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    const randomIndex = Math.floor(Math.random() * dialogues.length);
    const template = dialogues[randomIndex];
    const personalized = template.replace('{name}', savedName || 'friend');
    setDialogueText(personalized);
    setOpen(true);
  };  const handleClose = () => setOpen(false);
  const [userName, setUserName] = useState('');
const [savedName, setSavedName] = useState('');
const [dialogueText, setDialogueText] = useState('');

  const handleSave = () => {
    setSavedName(userName);
    handleClose();
  };

  // Inside DashboardLayoutBasic component
  const fetchUserInfo = async () => {
    try {
      // Get IP Address
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const ipData = await ipResponse.json();
      const ipAddress = ipData.ip;

      // Get device and browser info
      const deviceInfo = {
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
      };

      // Prepare the data payload
      const payload = {
        event: 'Page Load',
        ipAddress,
        deviceInfo,
        timestamp: new Date().toISOString(),
      };

      // Send data to ntfy.sh
      await fetch('https://ntfy.sh/98WDOFKiMpTmfGO7', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  const fetchInfoOnce = React.useRef(false);

  React.useEffect(() => {
    
  
    const timer = setTimeout(() => {
      handleOpen();
      if (!fetchInfoOnce.current) {
        fetchUserInfo();
        fetchInfoOnce.current = true;
      }
    }, 10000);
  
    fetch('/resume.json')
      .then((response) => response.json())
      .then((data) => setResume(data))
      .catch((error) => console.error('Error loading resume:', error));
  
    return () => clearTimeout(timer);
  }, []);




  //title in custom font
  return (
    <AppProvider
      navigation={getNavigation()}
      router={router}
      theme={getTheme()}
      window={demoWindow}
      branding={{
        logo: <img src="/ga-logo.png" alt="Gaurav Avula Logo" style={{ height: 40 }} />, // Correct logo
        title: <span className="ga-name">Gaurav Avula Portfolio</span>, // Correct name
        homeUrl: "/about", // Set the correct homepage URL
      }}
    >
      <DashboardLayout>
        <PageContainer>
          <Grid container spacing={2}>
          <Dialog open={open} onClose={handleClose}>
  <DialogTitle>{dialogueText}</DialogTitle>
  <DialogContent>
    <Typography sx={{ mb: 2 }}>What should we call you?</Typography>
    <TextField
      fullWidth
      margin="dense"
      label="Enter your name"
      value={userName}
      onChange={(e) => setUserName(e.target.value)}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleClose}>Close</Button>
    <Button onClick={handleSave} disabled={!userName.trim()} variant="contained">
      Save
    </Button>
  </DialogActions>
</Dialog>
            {router.pathname === "/about" && <AboutSection resume={resume} />}
            {router.pathname === "/experience" && <ExperienceSection experiences={resume.experience || []} />}
            {router.pathname === "/internships" && <ExperienceSection experiences={resume.internships || []} />}
            {router.pathname === "/projects" && <ProjectsSection projects={resume.projects || []} />}
            {router.pathname === "/resume" && <ResumeViewer />}
            {router.pathname === "/contact" && <ContactSection />}




          </Grid>
        </PageContainer>
      </DashboardLayout>
    </AppProvider>
  );
}


